import React, { useEffect, useState } from 'react';

import { Input, Table, Tooltip } from 'antd';
import { SearchOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';
import { HOST } from '../../config';

import PageTemplate from '../../components/PageTemplate';
import dateFormat from '../../utils/dateFormat';
import InfoDrawer from "../devices/components/InfoDrawer"

import styles from "./index.module.scss";

export default function Playlists() {
    const [search, setSearch] = useState("");

    const [data, setData] = useState([]);
    const [sort, setSort] = useState(['name', 'desc'])
    const [open, setOpen] = useState(false);
    const [currentDevice, setCurrentDevice] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => {
                return (currentPage - 1) * limit + index + 1;
            },
        },
        {
            title: "App",
            dataIndex: "app_name",
            align: "center",
            render: (text, record, index) => {
                return record && <div>
                    <img src={HOST + record.logo} alt="app logo" width={30} />
                    <div>{record.app_name}</div>
                </div>
            }
        },
        {
            title: "Name",
            dataIndex: "name",
            align: "center",
            sorter: true,
            render: (text) => <Tooltip title={text}>
                <div style={{ width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{text}</div>
            </Tooltip>
        },
        {
            title: "Url",
            dataIndex: "url",
            key: 'url',
            align: "center",
            width: 200,
            render: (text, record, index) => <Tooltip title={text}>
                <div style={{ width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{text}</div>
            </Tooltip>
        },
        {
            title: "Free DNS",
            dataIndex: "free_dns_id",
            align: "center",
            render: (text, record, index) => record?.free_dns_id ?
                <CheckOutlined style={{ color: "green" }} /> :
                <CloseOutlined style={{ color: "red" }} />
        },

        {
            title: "Protected",
            dataIndex: "pin",
            align: "center",
            render: (text, record, index) => record?.is_protected ? record.pin :
                <CloseOutlined style={{ color: "red" }} />
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "center",
            render: (text, record, index) => record?.status || "-"
        },
        {
            title: "Selected",
            align: "center",
            render: (text, record) => {
                return record.is_selected ?
                    <CheckOutlined style={{ color: "green" }} /> :
                    <CloseOutlined style={{ color: "red" }} />;
            },
        },
        {
            title: "Expired date",
            dataIndex: "expired_date",
            align: "center",
            render: (text, record) => text ? dateFormat(text) : "N/A"
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            align: "center",
            render: (text, record, index) => text ? dateFormat(text) : "N/A"
        },
    ];

    const tableOnChange = (pagination, filters, sorter) => {
        setSort([sorter.field, sorter.order === "ascend" ? "asc" : "desc"]);
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    }

    const getPlaylists = () => {
        setLoading(true);

        let searchValue = encodeURIComponent(search);

        const query = {
            search_key: searchValue,
        };

        const callback = (response) => {

            setLoading(false);

            let data = []

            for (let i = 0; i < response.length; i++) {
                for (let j = 0; j < response[i].playlists.length; j++) {
                    data.push({
                        app_name: response[i].name,
                        logo: response[i].logo,
                        app_id: response[i].id,
                        free_dns_enabled: response[i].free_dns_enabled,
                        ...response[i].playlists[j]
                    });
                }
            }

            setData(data);
        };

        const errorCallback = error => {
            setLoading(false);
        }

        REQUESTS.PLAYLISTS(query, callback, errorCallback)
    }

    const getDevice = (record) => {
        if (!record?.deviceId) return;
        const query = {
            id: record.deviceId,
            appId: record.app_id,
        };

        const callback = (data) => {
            setCurrentDevice({
                ...data.message,
                app_id: record.app_id,
            });
            setOpen(true);
        };

        REQUESTS.DEVICE(query, callback)
    }

    useEffect(() => {

        if (search === "") {
            setData([]);
            return;
        }

        let timeout = setTimeout(() => {
            getPlaylists();
        }, 1000)

        return () => {
            clearTimeout(timeout);
        }

    }, [search])

    useEffect(() => {
        if (data && data.length == 0) return;

        let sortedData = data.sort((a, b) => {
            if (sort[1] === "asc") {
                return a[sort[0]] > b[sort[0]] ? 1 : -1;
            } else {
                return a[sort[0]] < b[sort[0]] ? 1 : -1;
            }
        })

        setData([...sortedData])

    }, [sort])

    return (
        <PageTemplate title='Playlists'>
            <div className={styles['search-inp']}>
                <Input
                    placeholder='Search'
                    prefix={<SearchOutlined />}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                />
            </div>
            {
                data.length > 0 &&
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 'max-content' }}
                    size='small'
                    onChange={tableOnChange}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => {
                                getDevice(record)
                            }
                        }
                    }}
                    className={styles['table-row']}
                    pagination={{
                        pageSize: 10,
                        current: currentPage,
                        pageSize: limit,
                        showSizeChanger: true,
                    }}
                />
            }
            <InfoDrawer
                open={open}
                onClose={() => {
                    setOpen(false);
                    setCurrentDevice(null);
                }}
                device={currentDevice}
            />
        </PageTemplate>
    )
}
