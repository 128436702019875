import { useState, useEffect, lazy, Suspense, useMemo } from 'react';

import { Modal, Collapse, DatePicker, Table, Button, Empty, Flex, Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

import { REQUESTS } from '../../../api/requests';

// import PdfDownloadComponent from '../../../components/PdfDownloadComponent';

import formatNumber from '../../../utils/formatNumber';

import styles from "../index.module.scss";

const PdfDownloadComponent = lazy(() => import('../../../components/PdfDownloadComponent'));

export default function ResellerDNSStatisticModal({ reseller, open, onCancel }) {
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [data, setData] = useState([]);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);

  const onChange = (date, dateString) => {
    setYear(dateString.split('-')[0]);
    setMonth(dateString.split('-')[1]);

    if (typeof dateString === 'string' && dateString.length == 0) {
      let date = new Date();
      setYear(date.getFullYear());
      let month = date.getMonth() + 1;
      month = month < 9 ? '0' + month : month
      setMonth(month);
    }
  };

  const getAllDNS = () => {
    if (!reseller) return;

    const body = {
      reseller_id: reseller.id,
    };

    if (year && month) {
      body.year = year;
      body.month = month;
    }

    REQUESTS.FREE_DNS.FREE_DNS_STATISTICS_ALL(body, (res) => {

      let total = res.reduce((acc, item) => {
        item.apps.forEach(app => {
          if (app.statistics.error) return;
          Object.values(app.statistics.message).forEach((value) => {
            acc += value;
          });
        });
        return acc;
      }, 0);

      setData(res)

      setTotal(total);

      let items = res.map((item, index) => {
        let columns = [
          {
            title: 'App',
            dataIndex: 'name',
            key: 'name',
          },
        ];

        let dataSource = item.apps.map((app, idx) => {
          let data = {
            key: idx,
            name: app.name,
          };

          if (app.statistics.error) return data;

          Object.entries(app.statistics.message).forEach(([key, value]) => {
            if (!columns.some(col => col.key === key)) {
              columns.push({
                title: key ? key == "tizen" ? "Samsung" : key == "webos" ? "LG" : key[0].toUpperCase() + key.substring(1) : '',
                dataIndex: key,
                key: key,
                render: (text, record) => {
                  return <strong>{record[key]}</strong>
                }
              });
            }
            data[key] = value;
          });

          return data;
        });

        return {
          key: index,
          label: item.dns,
          children: <Table
            rowKey="id"
            scroll={{ x: 'max-content' }}
            pagination={false}
            bordered
            dataSource={dataSource}
            columns={columns}
            size='small'
            summary={pageData => {
              let columnTotals = {};
              columns.forEach(col => {
                if (col.dataIndex !== 'name') {
                  columnTotals[col.dataIndex] = pageData.reduce((acc, item) => acc + (item[col.dataIndex] || 0), 0);
                }
              });
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                    {columns.slice(1).map(col => (
                      <Table.Summary.Cell key={col.key}>
                        <strong>{columnTotals[col.dataIndex]}</strong>
                      </Table.Summary.Cell>
                    ))}
                  </Table.Summary.Row>
                </>
              );
            }}
          />,
        };
      });

      setItems(items);
    });
  };

  const pdfDownloadLink = useMemo(() => {
    let d = "";

    if (!year && !month) {
      let date = new Date();
      let y = date.getFullYear()
      let m = date.getMonth() + 1;
      m = m < 9 ? '0' + m : m
      d = `${y}-${m}`
    } else {
      d = `${year}-${month}`
    }

    return <Suspense fallback={<Spin indicator={<LoadingOutlined spin />} size="small" />}>
      <PdfDownloadComponent reseller={reseller} data={data} date={d} />
    </Suspense>;

  }, [reseller, data]);

  useEffect(() => {
    if (!open && !reseller) return;

    getAllDNS();
  }, [open, reseller, year, month]);

  useEffect(() => {
    if (!open && !reseller) {
      setItems([]);
      setData([]);
      setYear(null);
      setMonth(null);
    }
  }, [open, reseller]);

  return (
    <Modal
      title="Free DNS Statistics"
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose={true}
      width={1000}
    >
      <Flex justify="space-between">
        <DatePicker onChange={onChange} picker="month"
          defaultValue={year && month ? dayjs(`${year}-${month}`, 'YYYY-MM') : null}
        />
        {pdfDownloadLink}
      </Flex>

      {
        items.length ? (
          <div>
            <Collapse
              className={styles['collaps']}
              items={items}
            />
            <div className={styles['total-value']}>
              Total: {total}
            </div>
            <div className={styles['total-value']}>
              Total Price:{formatNumber(total * reseller?.free_dns_price) == 0 ? 0 : formatNumber(total * reseller?.free_dns_price) + " USD"}
            </div>
          </div>
        ) : (
          <Empty />
        )
      }
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" onClick={onCancel}>Close</Button>
      </div>
    </Modal>
  );
}

