import { useEffect, useState } from 'react';

import { Modal, DatePicker, Table, Flex, Button, Divider } from 'antd';

import dayjs from 'dayjs';

import { REQUESTS } from '../../../api/requests';

import styles from "../index.module.scss";

export default function Statistics({ current, open, onCancel, }) {
    const [data, setData] = useState([]);
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [loading, setLoading] = useState(null);

    const platforms = ["tizen", "webos", "android", "vidaa", "foxxum", "zeasn", "vizio", "tvos", "ios", "roku"];

    const getStatistics = () => {
        if (!current && !open) return;

        setLoading(true);

        const query = {
            id: current.id,
        }

        if (year && month) {
            query.year = year;
            query.month = month;
        }

        REQUESTS.FREE_DNS.FREE_DNS_STATISTICS(query, (res) => {
            setLoading(false)
            let data = res.apps;

            let dataModel = data.map(item => {
                let obj = {
                    api_url: item.api_url,
                    id: item.id,
                    name: item.name,
                };

                if (item.statistics.error) {
                    return obj
                } else {
                    return {
                        ...obj,
                        ...item.statistics.message
                    }
                }
            });

            setData(dataModel);

        }, (error) => { setLoading(false) })
    }

    const availablePlatforms = platforms.filter(platform => {
        return data?.some(item => item?.hasOwnProperty(platform))
    });

    const columns = [
        {
            title: 'App',
            dataIndex: 'name',
            key: 'name',
        },
        ...availablePlatforms.map(platform => ({
            title: platform ? platform == "tizen" ? "Samsung" : platform == "webos" ? "LG" : platform[0].toUpperCase() + platform.substring(1) : '',
            dataIndex: platform,
            key: platform,
            render: (text, record) => {
                return <strong>{record[platform]}</strong>
            }
        })),
    ]

    const onChange = (date, dateString) => {
        setYear(dateString.split('-')[0])
        setMonth(dateString.split('-')[1])

        if (typeof dateString == 'string' && dateString.length == 0) {
            let date = new Date()
            setYear(date.getFullYear())
            let month = date.getMonth() + 1
            month = month?.length == 1 ? month : '0' + month
            setMonth(month)
        }
    };

    useEffect(() => {
        let timeout;

        if (!open && !current) {
            setData([]);
            setYear(null);
            setMonth(null);
            return;
        } else {
            timeout = setTimeout(() => {
                getStatistics()
            }, 500)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [current, open, year, month]);

    return (
        <Modal
            title="Free DNS Statistic"
            open={open}
            destroyOnClose={true}
            onCancel={onCancel}
            footer={null}
        >
            <DatePicker onChange={onChange} picker="month" defaultValue={year && month ? dayjs(`${year}-${month}`, 'YYYY-MM') : null} />

            <Divider />

            <div className={styles['dns']}>{current?.dns} </div>

            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                loading={loading}
                scroll={{ x: 'max-content' }}
                summary={pageData => {
                    const totals = availablePlatforms.reduce((acc, platform) => {
                        acc[platform] = 0;
                        return acc;
                    }, {});

                    pageData.forEach(dataItem => {
                        availablePlatforms.forEach(platform => {
                            totals[platform] += dataItem[platform] || 0;
                        });
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                {availablePlatforms.map(platform => (
                                    <Table.Summary.Cell key={platform}>
                                        <strong>{totals[platform]}</strong>
                                    </Table.Summary.Cell>
                                ))}
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />

            <div className={styles['total']}>Total: {
                data.reduce((acc, item) => {
                    return acc + availablePlatforms.reduce((acc, platform) => {
                        return acc + (item[platform] || 0);
                    }, 0);
                }, 0)
            }</div>

            <Flex justify="flex-end" style={{ marginTop: 20 }}>
                <Button type='primary' onClick={onCancel}>Close</Button>
            </Flex>
        </Modal>
    )
}
