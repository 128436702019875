import request from "./request";
import URLS from "./url";
import requestQuery from "./requestQuery";

import axios from "axios";
const CryptoJS = require("crypto-js");

export const REQUESTS = {
  RECAPCHA_SITE_KEY: (callback, errorCallback,) => {
    request(
      "get",
      URLS.RECAPCHA_SITE_KEY,
      {},
      callback,
      errorCallback,
    );
  },
  LOGIN: (body, callback, errorCallback) => {
    request(
      "post",
      URLS.AUTH.ADMIN_LOGIN,
      body,
      callback,
      errorCallback,
    );
  },
  APPS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.APPS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  PROFILE: (callback, errorCallback) => {
    request(
      "get",
      URLS.PROFILE,
      {},
      callback,
      errorCallback,
    );
  },
  RESELLER: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.RESELLER + requestQuery(query),
        {},
        callback,
        errorCallback,
      );
    },
    CREATE: (body, callback, errorCallback) => {
      request(
        "post",
        URLS.RESELLER,
        body,
        callback,
        errorCallback,
      );
    },
    UPDATE: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.RESELLER,
        body,
        callback,
        errorCallback,
      );
    },
    BLOCK: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.RESELLER_BLOCK,
        body,
        callback,
        errorCallback,
      );
    }
  },
  CREDITS: {
    ADD: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.CREDITS.ADD,
        body,
        callback,
        errorCallback,
      );
    },
    TAKE: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.CREDITS.TAKE,
        body,
        callback,
        errorCallback,
      );
    },
  },
  RESELLER_DEVICES: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.RESELLER_DEVICES + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  GET_VERSION: (callback, errorCallback) => {
    request(
      "get",
      URLS.VERSION,
      {},
      callback,
      errorCallback,
    );
  },
  FREE_DNS: {
    GET: (params, callback, errorCallback) => {
      request(
        "get",
        URLS.FREE_DNS + requestQuery(params),
        {},
        callback,
        errorCallback,
      );
    },
    ADD: (body, callback, errorCallback) => {
      request(
        "post",
        URLS.FREE_DNS,
        body,
        callback,
        errorCallback,
      );
    },
    UPDATE: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.FREE_DNS,
        body,
        callback,
        errorCallback,
      );
    },
    DELETE: (body, callback, errorCallback) => {
      request(
        "delete",
        URLS.FREE_DNS,
        body,
        callback,
        errorCallback,
      );
    },
    RETRY: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.FREE_DNS_RETRY,
        body, callback, errorCallback
      );
    },
    FREE_DNS_STATISTICS_ALL: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.FREE_DNS_STATISTICS_ALL + requestQuery(query),
        {},
        callback,
        errorCallback,
      );
    },
    FREE_DNS_STATISTICS: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.FREE_DNS_STATISTICS + requestQuery(query),
        {},
        callback,
        errorCallback,
      );
    },
    FREE_DNS_DEVICES: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.FREE_DNS_DEVICES + requestQuery(query),
        {},
        callback,
        errorCallback,
      );
    },
  },
  APPS_DEVICES: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.APPS_DEVICES + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  ACTIVATE_APP: (body, callback, errorCallback) => {
    request(
      "post",
      URLS.ACTIVATE_APP,
      body,
      callback,
      errorCallback,
    );
  },
  FREE_TRIAL: (body, callback, errorCallback) => {
    request(
      "post",
      URLS.FREE_TRIAL,
      body,
      callback,
      errorCallback,
    );
  },
  DEACTIVATE_APP: (body, callback, errorCallback) => {
    request(
      "post",
      URLS.DEACTIVATE_APP,
      body,
      callback,
      errorCallback,
    );
  },
  PLAYLIST: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.PLAYLIST + requestQuery(query),
        {},
        callback,
        errorCallback,
      );
    },
    ADD: (body, callback, errorCallback) => {
      request(
        "post",
        URLS.ADD_PLAYLIST,
        body,
        callback,
        errorCallback,
      );
    },
    DELETE: (body, callback, errorCallback) => {
      request(
        "delete",
        URLS.DELETE_PLAYLIST,
        body,
        callback,
        errorCallback,
      );
    },
    EDIT: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.EDIT_PLAYLIST,
        body,
        callback,
        errorCallback,
      );
    },
  },
  DEVICE_ACTIVATIONS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.DEVICE_ACTIVATIONS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  DEVICE_PAYMNETS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.DEVICE_PAYMNETS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  PLAYLISTS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.PLAYLISTS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  DEVICE: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.DEVICE + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  NOTIFICATIONS: {
    request: (method, url, body, callback = () => { }, errorCallback = () => { }) => {
      let myAxios = axios.create();

      let TOKEN = localStorage.getItem("TOKEN");

      myAxios.defaults.headers = {
        "Authorization": `Bearer ${TOKEN}`,
        "x-access-token": REQUESTS.NOTIFICATIONS.CRYPTO(new Date().getTime().toString())
      }

      myAxios[method](url, method === "delete" ? { data: body } : body)
        .then((response) => {
          if (response?.data) {
            callback(response.data);
          }
        })
        .catch((error) => {
          let message = error?.response?.data
          if (message == "Forbidden") {
            localStorage.clear();
            window.location.href = "/";
            return;
          }

          if (message == "Your account is blocked" && !url.includes("login")) {
            localStorage.clear();
            window.location.href = "/";
            return;
          }

          errorCallback(error?.response?.data);
        });
    },
    CRYPTO: (str) => {
      try {
        return CryptoJS.AES.encrypt(str, "noeyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9t").toString();
      } catch (error) {
        console.error(error);
      }
    },
    GET: (params, callback, errorCallback) => {
      REQUESTS.NOTIFICATIONS.request(
        "get",
        URLS.NOTIFICATIONS + requestQuery(params),
        {},
        callback,
        errorCallback,
      );
    },
    CREATE: (body, callback, errorCallback) => {
      REQUESTS.NOTIFICATIONS.request(
        "post",
        URLS.NOTIFICATIONS,
        body,
        callback,
        errorCallback,
      );
    },
    EDIT: (body, callback, errorCallback) => {
      REQUESTS.NOTIFICATIONS.request(
        "put",
        URLS.NOTIFICATIONS,
        body,
        callback,
        errorCallback,
      );
    },
    DELETE: (body, callback, errorCallback) => {
      REQUESTS.NOTIFICATIONS.request(
        "delete",
        URLS.NOTIFICATIONS,
        body,
        callback,
        errorCallback,
      );
    },
    REPEAT: (body, callback, errorCallback) => {
      REQUESTS.NOTIFICATIONS.request(
        "put",
        URLS.NOTIFICATIONS,
        body,
        callback,
        errorCallback,
      );
    },
    FREEZE: (body, callback, errorCallback) => {
      REQUESTS.NOTIFICATIONS.request(
        "put",
        URLS.NOTIFICATIONS_FREEZE,
        body,
        callback,
        errorCallback,
      );
    },
    STATISTICS: (query, callback, errorCallback) => {
      REQUESTS.NOTIFICATIONS.request(
        "get",
        URLS.NOTIFICATIONS_STATISTICS + requestQuery(query),
        {},
        callback,
        errorCallback,
      );
    },
  }
}
