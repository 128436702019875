import { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';

// import ReCAPTCHA from 'react-google-recaptcha';

import { Button, Form, Input, Flex } from 'antd';

import { REQUESTS } from '../../api/requests';

import ICONS from '../../config/icons';

import Message from '../../components/Message';

import styles from "./login.module.scss"

export default function LoginForm() {
    const navigate = useNavigate();

    const [message, setMessage] = useState({ text: "", type: false })

    const [captchaValue, setCaptchaValue] = useState('');

    const [loading, setLoading] = useState(false)

    const [siteKey, setSiteKey] = useState(null);

    // const handleCaptchaChange = (value) => {
    //     setCaptchaValue(value);
    // };

    // const getSiteKey = () => {
    //     REQUESTS.RECAPCHA_SITE_KEY((data) => {
    //         setSiteKey(data.site_key)
    //     })
    // }

    const onFinish = (values) => {
        setLoading(true)
        setMessage({ text: "", type: false })
        const { email, password } = values;

        const body = {
            email: email.trim(),
            password: password.trim(),
            // token: captchaValue
        };


        REQUESTS.LOGIN(body,
            (data) => {
                setLoading(false)
                localStorage.setItem("TOKEN", data.message);
                navigate("/admin/dashboard/resellers")
            }, (error) => {
                setLoading(false)
                setCaptchaValue(null);
                setSiteKey(null);
                setMessage({ text: error, type: false })
            })
    };

    useEffect(() => {
        if (localStorage.getItem("TOKEN")) {
            navigate("/admin/dashboard/resellers")
        }
    }, []);

    // useEffect(() => {
    //     getSiteKey();
    // }, [siteKey])

    return (<>
        <div className={styles["form-container"]}>
            <div className={styles['icon']}>
                {ICONS.LOGIN_LOGO}
            </div>
            <h1 className={styles['title']}>
                Login to Admin
            </h1>
            <Form
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                onFinish={onFinish}
                layout='vertical'
                initialValues={{
                    email: "",
                    password: ""
                }}
                onValuesChange={() => {
                    setMessage({ text: "", type: false })
                }}
            >
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                        {
                            type: "email",
                            message: "Please enter valid email!"
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                        {
                            min: 8,
                            message: "Password must be at least 8 characters!"
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Flex justify='center'>
                    <Message message={message} />
                </Flex>
                {/* <div style={{ height: 84 }}>
                    {siteKey &&
                        <ReCAPTCHA
                            sitekey={siteKey}
                            onChange={handleCaptchaChange}
                        />
                    }
                </div> */}
                <Form.Item >
                    <Button
                        htmlType="submit"
                        // disabled={!captchaValue}
                        loading={loading}
                    >
                        Login
                    </Button>
                </Form.Item>
            </Form>
            {/*   <div className={styles['social-buttons']}>
                <Link
                    to="https://wa.me/34627571553"
                    target='_blank'
                >
                    {ICONS.WHATS_UP}
                    <div>
                        Contact us
                        <p>+34 627571553</p>
                    </div>
                </Link>
                <Link to="https://t.me/34627571553" target='_blank' className={styles['telegram']}>
                    {ICONS.TELEGRAM}

                    <div>
                        Contact us
                        <p>+34 627571553</p>
                    </div>
                </Link> 
            </div>*/}
            <div className={styles['social-icons']}>
                {/* <Link
                    to="https://wa.me/34627571553"
                    target='_blank'
                >
                    {ICONS.WHATS_UP}
                </Link> */}
                <Link to="https://t.me/multiplayerapps" target='_blank' className={styles['telegram']}>
                    {ICONS.TELEGRAM}
                </Link>
            </div>
        </div>
    </>
    )
}
