import { useDispatch, useSelector } from 'react-redux'

export default function useStore(selector) {
    const dispatch = useDispatch();

    const store = useSelector(state => state[selector]?.value);

    const setStore = (action) => dispatch(action);

    return [store, setStore]
}
