import { Routes, Route, } from "react-router-dom";

import { Helmet } from "react-helmet";

import { ConfigProvider } from "antd";

import { useScrollToTop } from "./hooks/use-scroll-to-top";

import AdminLayout from "./layouts/layout";

import Login from "./pages/auth";
import ResellersComponent from "./pages/resellers";
import FreeDns from "./pages/freeDNS";
import Devices from "./pages/devices";
import Playlists from "./pages/playlists";
import Notifications from "./pages/notifications";

function App() {
  useScrollToTop();

  return (<>
    <Helmet>
      <link rel="icon" href="./favicon.ico" />
      <link rel="apple-touch-icon" href="./favicon.ico" />
      <title>
        Multi Player Admin
      </title>
      <meta property="og:title" content="Multi Player" />
      <meta property="og:site_name" content="Multi Player" />
      <meta property="og:description"
        content="Manage 10 apps from one panel IPTV media player for Roku, Samsung Tizen, LG WebOS, Android TV, & Whale OS devices.TVOS, iOS & Android Mobile soon" />
      <meta property="og:image" content="./favicon.ico" />
      <meta
        name="description"
        content="Multi Player Admin"
      />
    </Helmet>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#429229',
          colorPrimaryBg: "#429229",
        },
        components: {
          Button: {
            colorPrimary: '#429229',
            algorithm: true,
            primaryShadow: "0 0 0 rgba(25, 55, 3, 0.23)",
            colorPrimaryBg: "#429229",
            hoverStyle: {
              colorPrimary: '#fff',
              colorPrimaryBg: "#429229",
            },
          },
          Menu: {
            colorPrimary: '#E4C30B',
          },
          Dropdown: {
            colorPrimary: '#fff',
          },
        }
      }}
    >
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin/dashboard" element={<AdminLayout />}>
          <Route path="resellers" element={<ResellersComponent />} />
          <Route path="free-dns" element={<FreeDns />} />
          <Route path="devices" element={<Devices />} />
          <Route path="playlists" element={<Playlists />} />
          <Route path="notifications" element={<Notifications />} />
        </Route>
      </Routes>
    </ConfigProvider >
  </>);
}

export default App;
