import { useEffect, useState } from 'react';

import { Drawer, Form, Input, Button, Flex, Checkbox, InputNumber, Select } from 'antd';

import { REQUESTS } from '../../../api/requests';

import Message from '../../../components/Message';

import passwordGenerator from '../../../utils/passwordGenerator';

import styles from "../index.module.scss";

const options = [
    { label: "500 * 0.4$/month", value: 0.4 },
    { label: "500 - 1000 * 0.3$/month", value: 0.3 },
    { label: "1000 - 5000 * 0.25$/month", value: 0.25 },
    { label: "5000 - 10000 * 0.2$/month", value: 0.2 },
    { label: "10k * 0.15$/month", value: 0.15 },
    { label: "Custom Offer", value: "Custom Offer" },
]

export default function ResellerDrawer({ onClose, open, current, getResellers }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });
    const [price, setPrice] = useState(0.4)

    const onFinish = (values) => {
        const { name, email, password, confirm_password, remark, is_partner, free_dns_price } = values;

        setMessage({
            type: false,
            text: ""
        });

        setLoading(true);

        if (current) {
            const body = {
                id: current.id,
                name: name.trim(),
                remark,
                email,
                is_partner,
                free_dns_price: price == "Custom Offer" ? free_dns_price : price
            }

            if (password || confirm_password) {
                body["password"] = password;
                body["confirm_password"] = confirm_password;
            }

            REQUESTS.RESELLER.UPDATE(
                body,
                (data) => {
                    setLoading(false)
                    onClose()
                    getResellers();
                },
                (err) => {
                    setLoading(false)
                    setMessage({
                        type: false,
                        text: err
                    })
                })
        } else {

            const body = {
                name: name.trim(),
                email,
                password,
                confirm_password,
                is_partner,
                free_dns_price: price == "Custom Offer" ? free_dns_price : price
            }

            if (remark) body.remark = remark;

            REQUESTS.RESELLER.CREATE(
                body,
                (data) => {
                    setLoading(false)
                    getResellers();
                    onClose()
                },
                (err) => {
                    setMessage({
                        type: false,
                        text: err
                    })
                    setLoading(false)
                })
        }
    }

    const randomPasswordGenerator = () => {
        let password = passwordGenerator();

        form.setFieldsValue({
            password: password,
            confirm_password: password
        })
    }

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setMessage({
                type: false,
                text: ""
            })
            setPrice(0.4)
        }
    }, [open])

    useEffect(() => {
        if (current) {
            form.setFields([
                {
                    name: "name",
                    value: current.name
                },
                {
                    name: "email",
                    value: current.email
                },
                {
                    name: "remark",
                    value: current.remark || ""
                },
                {
                    name: "free_dns_price",
                    value: current.free_dns_price
                },
                {
                    name: "is_partner",
                    value: current.is_partner
                },
            ])

            if (current.free_dns_price) {
                if (options.some(option => option.value == current.free_dns_price)) {
                    setPrice(current.free_dns_price)
                } else {
                    form.setFields([
                        {
                            name: "free_dns_price",
                            value: current.free_dns_price
                        },
                    ])
                    setPrice("Custom Offer")
                }
            }
        }
    }, [current])

    return (
        <Drawer
            title={current ? "Edit Reseller" : "Create Reseller"}
            onClose={onClose}
            open={open}
            destroyOnClose={true}
        >
            <Form
                form={form}
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                autoComplete="off"
                layout='vertical'
                onFinish={onFinish}
                initialValues={{
                    name: "",
                    email: "",
                    password: "",
                    remark: "",
                }}
                onValuesChange={(changedValues, allValues) => {
                    if (changedValues.free_dns_price) {
                        form.setFieldsValue({
                            free_dns_price: changedValues.free_dns_price
                        });
                    }
                }}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Name is required!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Email is required!',
                        },
                        {
                            type: "email",
                            message: "Please enter valid email!"
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="Remark"
                    rules={[
                        {
                            max: 255,
                            message: "Remark must be at least 255 characters"
                        }
                    ]}
                >
                    <Input.TextArea style={{ resize: "none" }} />
                </Form.Item>
                <div className={styles["free-dns-prices-container"]}>
                    <label htmlFor="" className=''>Free DNS Prices</label>
                    <Select
                        style={{ width: "100%" }}
                        options={options}
                        value={price}
                        onChange={(value) => {
                            setPrice(value)
                            if (value == "Custom Offer") {
                                form.setFieldsValue({
                                    free_dns_price: ''
                                })
                            }
                        }}
                        onClear={() => {
                            setPrice(0.4)
                        }}
                        allowClear
                    />
                </div>
                <div className={styles["free-dns-prices-container"]}>
                    {price == "Custom Offer" &&
                        <Form.Item
                            name="free_dns_price"
                        >
                            <Input />
                        </Form.Item>}
                </div>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: !current,
                            message: "Password is required!"
                        },
                        {
                            min: 8,
                            message: "Password must be at least 8 characters!"
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: !current,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Button
                    onClick={randomPasswordGenerator}
                    style={{ marginBottom: 10 }}
                >
                    Generate a Password
                </Button>
                <Form.Item
                    name="is_partner"
                    valuePropName="checked"
                    style={{ margin: 0 }}
                >
                    <Checkbox>Is Partner</Checkbox>
                </Form.Item>
                <Flex justify='center'>
                    <Message message={message} />
                </Flex>
                <Form.Item >
                    <Flex justify="center">
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                        >
                            Save
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Drawer >
    )
}

