
import { useEffect, useState } from 'react';

import { Button, Checkbox, Col, DatePicker, Drawer, Flex, Form, Input, Row, Select } from 'antd';

import dayjs from 'dayjs';

import { REQUESTS } from '../../../api/requests';

import styles from "../index.module.scss";
import Message from '../../../components/Message';
import TextEditor from './TextEditor';

export default function ResellersNotificationDrawer({ open, onClose, getData, actionType, current }) {
    const [form] = Form.useForm();

    const [select, setSelect] = useState({
        filter_type: null,
        filter_value: "",
    });

    const [checkedDate, setCheckedDate] = useState([]);

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const [editorValue, setEditorValue] = useState("");

    const onFinish = (values) => {
        setMessage({
            type: false,
            text: ""
        })
        const { title, expired_date, sended_date, apps } = values;

        const body = {
            title,
            desc: editorValue,
            filter_type: select.filter_type,
            filter_value: select.filter_value,
            type: "reseller"
        };

        if (select.filter_type !== "email" && select.filter_value !== "all") {
            body['filter_type'] = "type"
        }

        if (expired_date) {
            body['expired_date'] = dayjs(expired_date).format('YYYY-MM-DD');
        }

        if (sended_date) {
            body['sended_date'] = dayjs(sended_date).format('YYYY-MM-DD');
        }

        const callback = (data) => {
            getData();
            onClose();
        };

        const errorCallback = (error) => {
            setMessage({
                type: false,
                text: error
            });
        };
        if (actionType === "edit") {
            body['id'] = current.id;
            REQUESTS.NOTIFICATIONS.EDIT(body, callback, errorCallback)
        } else {
            REQUESTS.NOTIFICATIONS.CREATE(body, callback, errorCallback)
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();

            setSelect({
                filter_type: null,
                filter_value: null,
            });

            setCheckedDate([]);
            setMessage({
                type: false,
                text: ""
            })
            setEditorValue("");
        }
    }, [open, actionType, current])

    useEffect(() => {
        if (current && open) {
            if (actionType == 'edit' || actionType == "repeat") {

                form.setFieldsValue({
                    title: current.title,
                    expired_date: current.expired_date ? dayjs(current.expired_date) : null,
                    sended_date: current.sended_date ? dayjs(current.sended_date) : null,
                })
                setEditorValue(current.desc);
                setSelect({
                    filter_type: current.filter_type == "type" ? current.filter_value : current.filter_type,
                    filter_value: current.filter_value,
                })

                setCheckedDate(current.sended_date && current.expired_date ? ["sended_date", "expired_date"] : current.sended_date ? ["sended_date"] : current.expired_date ? ["expired_date"] : []);
            }
        }
    }, [open, actionType, current])

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={actionType == "edit" ? 'Update Notification' :
                actionType == "repeat" ? 'Repeat Notification' : 'Create Notification'}
            destroyOnClose={true}
            width={700}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input title!' }]}
                >
                    <Input />
                </Form.Item>
                <label className={styles['label-val']}>Text</label>
                <TextEditor value={editorValue} setValue={setEditorValue} />
                <>
                    <label className={styles['label-val']}>Send To</label>
                    <Select
                        style={{ width: '100%', marginBottom: 16 }}
                        value={select.filter_type}
                        options={[
                            { label: 'All', value: 'all' },
                            { label: 'Reseller', value: 'reseller' },
                            { label: 'Subreseller', value: 'subreseller' },
                            { label: 'Partner', value: 'partner' },
                            { label: 'Email', value: 'email' },
                        ]}
                        onChange={(val) => setSelect({
                            filter_type: val,
                            filter_value: val == "email" ? "" : val,
                        })}
                    />
                </>

                <div style={{ marginBottom: 16 }}>
                    {
                        select.filter_type == "email" && <>
                            <label className={styles['label-val']}>Email</label>
                            <Input
                                type='email'
                                value={select.filter_value}
                                onChange={(e) => setSelect({
                                    ...select,
                                    filter_value: e.target.value,
                                })} />
                        </>
                    }
                </div>

                <Checkbox.Group
                    value={checkedDate}
                    onChange={setCheckedDate}
                    style={{
                        width: '100%',
                        marginBottom: 16
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={16}>
                            <Checkbox value="sended_date">Send Date</Checkbox>
                        </Col>
                        <Col span={16}>
                            {checkedDate.includes("sended_date") &&
                                <Form.Item
                                    name="sended_date"
                                    style={{ marginBottom: 0 }}
                                >
                                    <DatePicker disabledDate={(current) => current && current < dayjs().endOf('day')} />
                                </Form.Item>}
                        </Col>
                        <Col span={16}>
                            <Checkbox value="expired_date">Expired Date</Checkbox>
                        </Col>
                        <Col span={16}>
                            {checkedDate.includes("expired_date") &&
                                <Form.Item
                                    name="expired_date"
                                    style={{ marginBottom: 0 }}
                                >
                                    <DatePicker disabledDate={(current) => current && current < dayjs().endOf('day')} />
                                </Form.Item>}
                        </Col>
                    </Row>
                </Checkbox.Group>
                <Message message={message} />
                <Flex justify='center' align='center'>
                    <Form.Item>
                        <Button htmlType='submit' type='primary'>Save</Button>
                    </Form.Item>
                </Flex>
            </Form>
        </Drawer >
    )
}
