import React, { useEffect, useState } from 'react';

import { Table, Modal } from 'antd';

import { REQUESTS } from '../../../api/requests';

import useResize from '../../../hooks/use-resize';

export default function StatisticsPopup({ open, onClose, current }) {
    const [data, setData] = useState([]);

    const resize = useResize();

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text, record, index) => index + 1
        },
        {
            title: "App Name",
            dataIndex: "app_name",
            key: "app_name",
        },
        {
            title: "Sended",
            dataIndex: "sended",
            key: "sended",
        },
        {
            title: "Received",
            dataIndex: "viewed",
            key: "viewed",
        },
    ]

    const getStatistics = () => {
        if (!current) return;

        const query = {
            id: current.id,
        };

        const callback = (data) => {
            setData(data);
        };

        REQUESTS.NOTIFICATIONS.STATISTICS(query, callback)
    }

    useEffect(() => {
        if (open && current) {
            getStatistics();
        } else {
            setData([]);
        }
    }, [current, open])

    return (
        <Modal
            title="Statistics"
            open={open}
            onCancel={onClose}
            destroyOnClose
            footer={null}
        >
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                bordered
                scroll={{ x: "max-content" }}
                size={resize <= 900 ? "small" : "large"}
            />
        </Modal>
    )
}
