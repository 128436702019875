
import { memo, useEffect, useState, useCallback, } from 'react';

import { Table, Button, Flex, Popover, Input, Select, Tooltip, Tag } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    StockOutlined,
    SearchOutlined,
    DesktopOutlined,
    FundProjectionScreenOutlined
} from '@ant-design/icons';

import classNames from 'classnames';

import { REQUESTS } from '../../api/requests';

import PageTemplate from '../../components/PageTemplate';

import useResize from '../../hooks/use-resize';

import ActionDropdown from '../../components/ActionDropdown';
import filterDropdown from '../../components/FilterDropdown';

import dateFormat from '../../utils/dateFormat';
import ConfirmModal from '../../utils/ConfirmModal';

import DnsDrawer from './components/DnsDrawer';
import Statistics from './components/Statistics';
import SyncStatus from './components/SyncStatus';
import FreeDnsDevice from './components/FreeDnsDevices';

import styles from "./index.module.scss";

let timeout

function FreeDns() {
    const [data, setData] = useState([]);
    const [resellers, setResellers] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);

    const [open, setOpen] = useState(false);

    const [showRetryModal, setShowRetryModal] = useState(false);
    const [statisticsOpen, setStatisticsOpen] = useState(false);
    const [current, setCurrent] = useState(null);
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState(["updatedAt", "DESC"]);
    const [search, setSearch] = useState("");
    const [date, setDate] = useState('');
    const [showFreeDnsDevice, setShowFreeDnsDevice] = useState(false);

    const resize = useResize()

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                return (currentPage - 1) * limit + index + 1
            }
        },
        {
            title: "DNS",
            dataIndex: 'dns',
            sorter: true,
            render: (text) => {
                return <Tooltip title={text}>
                    <div style={{ width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{text}</div>
                </Tooltip>
            }
        },
        {
            title: "Remark",
            dataIndex: 'remark',
            sorter: true,
            render: (text) => {
                return text ? <Tooltip title={text}>
                    <div style={{ width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{text}</div>
                </Tooltip> : "-"
            }
        },
        {
            title: "App Count",
            dataIndex: 'app_count',
            sorter: true,
            render: (text, record) => {
                return record.app_count
            }
        },
        {
            title: "Reseller",
            dataIndex: 'reseller_id',
            sorter: true,
            render: (text, record) => {
                let reseller = resellers.find(res => res.value == record.reseller_id);
                return <div>
                    <p>{reseller.label}</p>
                    <p>{`(${reseller.email})`}</p>
                </div>
            }
        },
        {
            title: "Status",
            dataIndex: 'blocked',
            filters: [
                {
                    text: 'Blocked',
                    value: true,
                },
                {
                    text: 'Active',
                    value: false,
                },
            ],
            filterMultiple: false,
            render: (text, record) => record.blocked ? <Tag color='default'>Blocked</Tag> : <Tag color='green'>Active</Tag>
        },
        {
            title: "Sync Status",
            dataIndex: 'sync_status',
            align: "center",
            filters: [
                {
                    text: 'Success',
                    value: "success",
                },
                {
                    text: 'Pending',
                    value: "pending",
                },
                {
                    text: 'Failed',
                    value: "failed",
                },
            ],
            filterMultiple: false,
            render: (text, record) => {
                return record?.sync_status == "pending" || record?.sync_status == "success" ?
                    <div
                        className={classNames(
                            styles['sync-status'],
                            { [styles["pending"]]: record?.sync_status == "pending" },
                            { [styles["success"]]: record?.sync_status == "success" }
                        )}
                    > {record?.sync_status}</div> :
                    <Popover
                        trigger="click"
                        open={showRetryModal}
                        onOpenChange={() => {
                            setShowRetryModal(false);
                        }}
                        arrow={false}
                        placement='bottom'
                        content={<SyncStatus current={record} onClose={() => setShowRetryModal(false)} />}
                    >
                        <Button type='primary'
                            danger
                            onClick={() => {
                                setCurrent(record);
                                setShowRetryModal(true)
                            }}
                            style={{ width: 100, height: 32 }}
                        >
                            {record?.sync_status}
                        </Button>
                    </Popover >
            }
        },
        {
            title: "Created Date",
            dataIndex: 'createdAt',
            sorter: true,
            ...filterDropdown(setDate),
            render: (text, record) => dateFormat(text)
        },
        {
            title: "Action",
            key: 'action',
            align: "center",
            render: (text, record) => {
                return <ActionDropdown items={[
                    {
                        key: "Devices By Statistics",
                        label: <div >
                            <FundProjectionScreenOutlined style={{ cursor: "pointer" }} /> Devices By Statistics
                        </div>,
                        onClick: (e) => handleAction(e, record)
                    },
                    {
                        key: "Devices By Total",
                        label: <div >
                            <DesktopOutlined style={{ cursor: "pointer" }} /> Devices By Total
                        </div>,
                        onClick: (e) => handleAction(e, record)
                    },
                    {
                        key: "Statistics",
                        label: <div >
                            <StockOutlined style={{ cursor: "pointer" }} /> Statistics
                        </div>,
                        onClick: (e) => handleAction(e, record)
                    },
                    {
                        key: "Edit",
                        label: <div >
                            <EditOutlined style={{ cursor: "pointer" }} /> Edit
                        </div>,
                        onClick: (e) => handleAction(e, record)
                    },
                    {
                        key: "Delete",
                        label: <div >
                            <DeleteOutlined style={{ cursor: "pointer" }} /> Delete
                        </div>,
                        onClick: (e) => handleAction(e, record)
                    },
                ]} />
            },
        },
    ];

    const deleteAction = (id) => {
        REQUESTS.FREE_DNS.DELETE({ id }, () => {
            getFreeDNS();
        })
    };

    const handleAction = (e, item) => {
        switch (e.key) {
            case "Devices By Total":
                setCurrent(item);
                setShowFreeDnsDevice(true);
                break;
            case "Statistics":
                setCurrent(item);
                setStatisticsOpen(true);
                break;
            case "Edit":
                setCurrent(item);
                setOpen(true);
                break;
            case "Delete":
                ConfirmModal("Do you want to delete this DNS?", () => deleteAction(item.id))
                break;
            default:
                break;
        }
    };

    const handleOnChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current)
        setLimit(pagination.pageSize)
        setFilter(filters)
        if (sorter.field && sorter.order) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        } else {
            setSort(["updatedAt", "DESC"])
        }
    };

    const getFreeDNS = () => {

        const params = {
            limit: limit,
            page: currentPage,
            sort,
        }

        if (search.length) {
            params.search = {
                dns: search,
                remark: search,
            }
        }

        if (filter["sync_status"]) {
            params["filter"] = {
                sync_status: filter["sync_status"][0]
            }
        }

        if (filter["reseller_id"]) {
            params["filter"] = {
                reseller_id: filter["reseller_id"][0]
            }
        }

        if (filter["blocked"]) {
            params["filter"] = {
                blocked: filter["blocked"][0]
            }
        }

        const dateFilter = [
            { date: date, between: "createdAt" },
        ]

        dateFilter.forEach((item) => {
            if (item.date.length && item.date[0] && item.date[1]) {
                params['between'] = {
                    [item.between]: {
                        from: item.date[0] + " 00:00",
                        to: item.date[1] + " 23:59"
                    }
                }
            }
        })

        REQUESTS.FREE_DNS.GET({ query: JSON.stringify(params) },
            (data) => {
                setData(data.rows);
                setTotal(data.count);
            }, () => { })
    };

    const getResellers = useCallback(() => {

        const query = {
            pagination: false,
            filter: {
                is_partner: true,
            }
        };

        const callback = (res) => {
            let data = res.map((reseller) => {
                return {
                    value: reseller.id,
                    label: reseller.name,
                    email: reseller.email,
                }
            })
            setResellers(data)
        };

        REQUESTS.RESELLER.GET({ query: JSON.stringify(query), }, callback)
    }, [])

    useEffect(() => {
        getResellers();
    }, [])

    useEffect(() => {
        timeout = setTimeout(() => {
            getFreeDNS()
        }, 500);

        return () => {
            clearTimeout(timeout)
        }
    }, [limit, currentPage, sort, search, date, filter]);

    return (
        <PageTemplate title="Free DNS">
            <Flex justify='space-between' style={{ marginBottom: 20 }}>
                <Flex gap={10} wrap='wrap'>
                    <Input
                        placeholder="Search DNS"
                        prefix={<SearchOutlined />}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        style={{ width: 300 }}
                    />
                    <Select
                        style={{ width: 250 }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={resellers}
                        onChange={(value) => {
                            setFilter({ ...filter, reseller_id: [value] })
                        }}
                        allowClear
                        placeholder="Select Reseller"
                    />
                </Flex>
                <Button onClick={() => {
                    setOpen(true);
                    if (current) setCurrent(null);
                }} type="primary">
                    <PlusOutlined /> Add DNS
                </Button>
            </Flex>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleOnChange}
                pagination={{
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: "max-content" }}
                size={resize <= 900 ? "small" : "large"}
                rowClassName={(record, index) => record.blocked ? styles['blocked'] : ''}
            />
            <DnsDrawer
                open={open}
                onClose={() => {
                    setOpen(false);
                    if (current) setCurrent(null);
                }}
                current={current}
                getFreeDNS={getFreeDNS}
                resellers={resellers}
            />
            <Statistics
                open={statisticsOpen}
                current={statisticsOpen ? current : statisticsOpen}
                onCancel={() => {
                    setStatisticsOpen(false);
                    if (current) setCurrent(null);
                }}
            />

            <FreeDnsDevice
                open={showFreeDnsDevice}
                onClose={() => {
                    setShowFreeDnsDevice(false);
                    if (current) setCurrent(null);
                }}
                current={current}
            />
        </PageTemplate>
    )
}

export default memo(FreeDns);