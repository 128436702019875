import { useEffect, useState } from 'react';

import { Button, Checkbox, Col, DatePicker, Drawer, Flex, Form, Input, Row, Select } from 'antd';

import dayjs from 'dayjs';

import { REQUESTS } from '../../../api/requests';

import ValidateMacAddress from '../../../components/ValidateMacAddress';
import Message from '../../../components/Message';

import TextEditor from './TextEditor';

import styles from "../index.module.scss";

const apps = [
    {
        "logo": "iptv_player_io.png",
        "id": 1,
        "name": "IPTV Player io",
        checked: true,
    },
    {
        "logo": "iptv_ott_player.png",
        "id": 2,
        "name": "IPTV OTT player",
        checked: true,
    },
    {
        "logo": "iptv_4k.png",
        "id": 3,
        "name": "IPTV 4K",
        checked: true,
    },
    {
        "logo": "iptv_stream_player.png",
        "id": 4,
        "name": "IPTV Stream player",
        checked: true,
    },
    {
        "logo": "iptv_player.png",
        "id": 5,
        "name": "IPTV Player",
        checked: true,
    },
    {
        "logo": "iptv_play.png",
        "id": 6,
        "name": "IPTV Play",
        checked: true,
    },
    {
        "logo": "iptv_plus.jpg",
        "id": 7,
        "name": "IPTV Plus",
        checked: true,
    },
    {
        "logo": "iptv_pro.png",
        "id": 8,
        "name": "IPTV Pro",
        checked: true,
    },
    {
        "logo": "pro_player.png",
        "id": 9,
        "name": "PRO Player",
        checked: true,
    },
    {
        "logo": "iptv_star.png",
        "id": 10,
        "name": "IPTV Star",
        checked: true,
    },
]

export default function AppsNotificationDrawer({ open, onClose, getData, actionType, current }) {
    const [form] = Form.useForm();

    const [select, setSelect] = useState({
        filter_type: null,
        filter_value: null,
    });

    const [mac, setMac] = useState("");

    const [checkedDate, setCheckedDate] = useState([]);

    const [appsList, setAppsList] = useState(apps);

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const [editorValue, setEditorValue] = useState("");

    const [checkedAll, setCheckedAll] = useState(true);

    const onFinish = (values) => {
        setMessage({
            type: false,
            text: ""
        });

        const { title, expired_date, sended_date, apps } = values;

        const body = {
            title,
            desc: editorValue,
            filter_type: select.filter_type,
            filter_value: select.filter_value,
            type: "device",
            apps: appsList.filter(app => app.checked).map(app => app.name)
        };

        if (select.filter_type === "mac") {
            body['filter_value'] = mac;
        }

        if (expired_date) {
            body['expired_date'] = dayjs(expired_date).format('YYYY-MM-DD');
        }

        if (sended_date) {
            body['sended_date'] = dayjs(sended_date).format('YYYY-MM-DD');
        }

        const callback = (data) => {
            getData();
            onClose();
        };

        const errorCallback = (error) => {
            setMessage({
                type: false,
                text: error
            });
        };

        if (actionType === "edit") {
            body['id'] = current.id;
            REQUESTS.NOTIFICATIONS.EDIT(body, callback, errorCallback)
        } else {
            REQUESTS.NOTIFICATIONS.CREATE(body, callback, errorCallback)
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();

            setSelect({
                filter_type: null,
                filter_value: null,
            });

            setMac("");
            setCheckedDate([]);
            setAppsList([]);
            setMessage({
                type: false,
                text: ""
            });
            setCheckedAll(true);
            setEditorValue("");
        } else {
            setAppsList(apps);
        }
    }, [open])

    useEffect(() => {
        if (current && open) {
            if (actionType == 'edit' || actionType == "repeat") {
                form.setFieldsValue({
                    title: current.title,
                    desc: current.desc,
                    expired_date: current.expired_date ? dayjs(current.expired_date) : null,
                    sended_date: current.sended_date ? dayjs(current.sended_date) : null,
                });

                setEditorValue(current.desc);

                setSelect({
                    filter_type: current.filter_type,
                    filter_value: current.filter_value,
                });

                if (current.filter_type === "mac") setMac(current.filter_value);

                setCheckedDate(current.sended_date && current.expired_date ? ["sended_date", "expired_date"] : current.sended_date ? ["sended_date"] : current.expired_date ? ["expired_date"] : []);

                setAppsList(apps.map(app => {
                    if (current.apps.includes(app.name)) {
                        return { ...app, checked: true }
                    }
                    return { ...app, checked: false }
                }))
            }
        }
    }, [open, actionType, current])

    const handleCheckAll = () => {
        const newCheckedAll = !checkedAll;
        setCheckedAll(newCheckedAll);
        setAppsList(appsList.map(app => ({ ...app, checked: newCheckedAll })));
    };

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={actionType == "edit" ? 'Update Notification' :
                actionType == "repeat" ? 'Repeat Notification' : 'Create Notification'}
            destroyOnClose={true}
            width={700}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input title!' }]}
                >
                    <Input />
                </Form.Item>
                <label className={styles['label-val']}>Text</label>
                <TextEditor value={editorValue} setValue={setEditorValue} />

                <>
                    <label className={styles['label-val']}>Devices By</label>
                    <Select
                        style={{ width: '100%', marginBottom: 16 }}
                        value={select.filter_type}
                        options={[
                            { label: 'All', value: 'all' },
                            { label: 'Platforms', value: 'platform' },
                            { label: 'Status', value: 'status' },
                            { label: 'Mac', value: 'mac' },
                        ]}
                        onChange={(val) => setSelect({
                            filter_type: val,
                            filter_value: val == "all" ? "all" : null,
                        })}
                    />
                </>
                <div style={{ marginBottom: 16 }}>
                    {(() => {
                        switch (select.filter_type) {
                            case "platform":
                                return <Select
                                    style={{ width: '100%' }}
                                    value={select.filter_value}
                                    options={[
                                        { label: 'Tizen', value: 'tizen' },
                                        { label: 'WebOS', value: 'webos' },
                                        { label: 'Android', value: 'android' },
                                        { label: 'Vidaa', value: 'vidaa' },
                                        { label: 'Foxxum', value: 'foxxum' },
                                        { label: 'Zeasn', value: 'zeasn' },
                                        { label: 'Vizio', value: 'vizio' },
                                        { label: 'TvOS', value: 'tvos' },
                                        { label: 'IOS', value: 'ios' },
                                        { label: 'Roku', value: 'roku' },
                                        { label: 'Android Mobile', value: 'android-mobile' },
                                    ]}
                                    onChange={(val) => setSelect({
                                        ...select,
                                        filter_value: val,
                                    })}
                                />
                            case "mac":
                                return <ValidateMacAddress value={mac} setValue={setMac} />
                            case "status":
                                return <Checkbox.Group
                                    value={[select.filter_value]}
                                    onChange={(val) => setSelect({
                                        ...select,
                                        filter_value: val[val.length - 1],
                                    })}
                                >
                                    <Row gutter={[16, 8]}>
                                        <Col span={16}>
                                            <Checkbox value="free_trial">Free Trial</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="free_trial_expired">Free Trial Expired</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="payed">Activated</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            default:
                                break;
                        }
                    })()}
                </div>

                <Checkbox.Group
                    value={checkedDate}
                    onChange={setCheckedDate}
                    style={{
                        width: '100%',
                        marginBottom: 16
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={16}>
                            <Checkbox value="sended_date">Send Date</Checkbox>
                        </Col>
                        <Col span={16}>
                            {checkedDate.includes("sended_date") &&
                                <Form.Item
                                    name="sended_date"
                                    style={{ marginBottom: 0 }}
                                >
                                    <DatePicker disabledDate={(current) => current && current < dayjs().endOf('day')} />
                                </Form.Item>}
                        </Col>
                        <Col span={16}>
                            <Checkbox value="expired_date">Expired Date</Checkbox>
                        </Col>
                        <Col span={16}>
                            {checkedDate.includes("expired_date") &&
                                <Form.Item
                                    name="expired_date"
                                    style={{ marginBottom: 0 }}
                                >
                                    <DatePicker disabledDate={(current) => current && current < dayjs().endOf('day')} />
                                </Form.Item>}
                        </Col>
                    </Row>
                </Checkbox.Group>

                <Col span={16}>
                    <Checkbox
                        checked={checkedAll}
                        onClick={handleCheckAll}
                    >
                        Check All
                    </Checkbox>
                </Col>
                <div className={styles['apps-list']}>
                    {
                        appsList
                            .map(app => <div key={app.id} >
                                <Checkbox
                                    value={app.name}
                                    style={{ width: '100%' }}
                                    checked={app.checked}
                                    onChange={(e) => {
                                        let checked = e.target.checked;
                                        const updatedAppsList = appsList.map(element => {
                                            if (element.id === app.id) {
                                                return { ...element, checked };
                                            }
                                            return element;
                                        });

                                        setAppsList(updatedAppsList);
                                        setCheckedAll(updatedAppsList.every(app => app.checked));
                                    }}
                                >
                                    <Flex align='center' gap={8}>
                                        {app.name}
                                    </Flex>
                                </Checkbox>
                            </div>)
                    }
                </div>
                <Message message={message} />
                <Flex justify='center' align='center' >
                    <Form.Item>
                        <Button htmlType='submit' type='primary' style={{ marginTop: 24 }}>Save</Button>
                    </Form.Item>
                </Flex>
            </Form>
        </Drawer >
    )
}
