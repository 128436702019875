import { configureStore } from '@reduxjs/toolkit';

import profileReducer from './features/profile/profileSlice';
import appsReducer from './features/apps/appsSlice';

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    apps: appsReducer,
  },
});
