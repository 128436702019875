
import { useEffect, useState } from 'react';

import { Drawer, Form, Input, Button, Flex, Alert } from 'antd';

import { REQUESTS } from '../../../api/requests';

import Message from '../../../components/Message';

import useStore from '../../../hooks/use-store';

export default function CreditsDrawer({ onClose, creditUpdate, current, getResellers }) {
    const [form] = Form.useForm();

    const [profile] = useStore("profile")

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        const { credit } = values;

        setMessage({
            type: false,
            text: ""
        })

        setLoading(true)

        const body = {
            id: current.id,
            credit
        }

        if (creditUpdate === "Add Credit") {
            REQUESTS.CREDITS.ADD(body, (data) => {
                setLoading(false)
                getResellers();
                onClose()
            }, (err) => {
                setLoading(false)
            })
        } else {
            REQUESTS.CREDITS.TAKE(body, (data) => {
                setLoading(false)
                getResellers();
                onClose()
            }, (err) => {
                setLoading(false)
                setMessage({
                    type: false,
                    text: err
                })
            })
        }
    }
    const validateCredits = (_, value) => {
        if (value < 0) {
            return Promise.reject('Credit value must be greater than or equal to 0!');
        } else if (creditUpdate === "Take Credit" && value > current?.credits) {
            return Promise.reject('Credit value must be less than or equal to reseller credits!');
        }

        return Promise.resolve();
    };

    useEffect(() => {
        if (!creditUpdate) {
            form.resetFields();
            setMessage({
                type: false,
                text: ""
            })
        }
    }, [creditUpdate])

    return (
        <Drawer
            title={creditUpdate}
            onClose={onClose}
            open={creditUpdate}>
            <Form
                form={form}
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                autoComplete="off"
                layout='vertical'
                onFinish={onFinish}
                initialValues={{
                    credit: ""
                }}
            >
                <Form.Item
                    name="credit"
                    label="Credits"
                    rules={[
                        {
                            required: true,
                            message: "Credits is required!"
                        },
                        {
                            validator: validateCredits,
                        },
                    ]}>
                    <Input
                        type='number'
                        min={0}
                    />
                </Form.Item>
                <Flex justify='center'>
                    <Message message={message} />
                </Flex>
                <Form.Item >
                    <Flex justify="center">
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                        >
                            Save
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
