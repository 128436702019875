import { useEffect, useState } from "react";

import { Table, notification, Modal } from "antd";
import {
    SearchOutlined,
    ExpandAltOutlined,
    TagOutlined,
} from "@ant-design/icons";

import PageTemplate from "../../components/PageTemplate";
import ValidateMacAddress from "../../components/ValidateMacAddress";
import ActionDropdown from "../../components/ActionDropdown";

import ConfirmModal from "../../utils/ConfirmModal";
import dateFormat from "../../utils/dateFormat";

import { REQUESTS } from "../../api/requests";

import ICONS from "../../config/icons";

import InfoDrawer from "./components/InfoDrawer";

import styles from "./index.module.scss";
import { HOST } from "../../config";

const { confirm } = Modal;

export default function Devices() {
    const [mac, setMac] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(null);
    const [open, setOpen] = useState(false);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text, record, index) => index + 1,
        },
        {
            title: "App",
            dataIndex: "name",
            key: "name",
            align: "center",
            render: (text, record) => {
                return record && <div>
                    <img src={HOST + record.logo} alt="app logo" width={30} />
                    <div>{record.name}</div>
                </div>
            }
        },
        {
            title: "Model",
            dataIndex: "model",
            key: "model",
            align: "center",
            render: (text, record) => record && <div>
                <div>{record.model}</div>
                <div>{record.os_version}</div>
            </div>

        },
        {
            title: "App Version",
            dataIndex: "app_version",
            key: "app_version",
            align: "center",
        },
        {
            title: "Online",
            dataIndex: "online",
            key: "online",
            align: "center",
            render: (text, record) => (record.online && "Online") || (record.last_online && dateFormat(record.last_online)) || "N/A"
        },
        {
            title: "Status",
            align: "center",
            dataIndex: "status",
            render: (text, record) => record.payed ? "Paid" : record.free_trial == 1 ? "Free trial" : record.free_trial == 2 ? "Free trial expired" : "None",
        },
        {
            title: "Expired date",
            align: "center",
            dataIndex: "activation_expired",
            render: (text) => text ? dateFormat(text) : "N/A",
        },
        {
            title: "Free trial expired",
            align: "center",
            dataIndex: "free_trial_expired",
            render: (text) => text ? dateFormat(text) : "N/A",
        },
        {
            title: "Ip",
            align: "center",
            dataIndex: "ip",
            key: "ip",
            render: (text) => text || "N/A",
        },
        {
            title: "Created date",
            align: "center",
            dataIndex: "createdAt",
            render: (text) => text ? dateFormat(text) : "N/A",
        },
        {
            title: "",
            width: "60px",
            dataIndex: "action",
            align: "center",
            render: (text, record) => (
                <ActionDropdown
                    items={[
                        {
                            key: "free_trial",
                            label: "Free trial",
                            icon: <ExpandAltOutlined />,
                            disabled: (record.free_trial < 2 || record.payed),
                            onClick: () => ConfirmModal("Are you sure you want to free trial?", () => handleActions("free_trial", record)),

                        },
                        {
                            key: "activate",
                            label: "Activate",
                            icon: <TagOutlined />,
                            disabled: record.payed,
                            onClick: () => ConfirmModal("Are you sure you want to activate device?", () => handleActions("activate", record)),
                        },
                        {
                            key: "deActivate",
                            label: "Deactivate",
                            icon: ICONS.DEACTIVE,
                            disabled: !(record.free_trial == 1 || record.payed),
                            onClick: () => ConfirmModal("Are you sure you want to deactivate device?", () => handleActions("deActivate", record)),
                        },
                    ]
                    }
                />
            ),
        }
    ];

    const getApps = () => {
        setLoading(true);

        const query = {
            mac,
        }

        const callback = (response) => {
            let res = response.map((item) => {
                return {
                    name: item.name,
                    logo: item.logo,
                    app_id: item.id,
                    ...item.devices
                };
            });

            setData(res);
            setLoading(false);
        }

        const errorCallback = (error) => {
            setLoading(false);
        }

        REQUESTS.APPS_DEVICES(query, callback, errorCallback)
    };

    const handleActions = (type, item) => {
        if (!item) return;

        const { app_id, id } = item;

        const body = {
            app_id,
            device_id: id,
        };

        switch (type) {
            case "activate":
                REQUESTS.ACTIVATE_APP(body, (res) => {
                    getApps();
                }, (err) => {
                    notification.error({ message: "Failed to activate app" })
                })
                break;
            case "deActivate":
                REQUESTS.DEACTIVATE_APP(body, (res) => {
                    getApps();
                }, (err) => {
                    notification.error({ message: "Failed to deactivate app" })
                })
                break;
            case "free_trial":
                REQUESTS.FREE_TRIAL(body, (res) => {
                    getApps();
                }, (err) => {
                    notification.error({ message: "Failed to free trial app" })
                })
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (!mac) return;

        setData([]);

        let timeout = setTimeout(() => {
            getApps();
        }, 300);

        return () => {
            clearTimeout(timeout);
        }
    }, [mac])

    return (
        <PageTemplate title="Devices">
            <div className={styles['search-inp-container']}>
                <ValidateMacAddress
                    value={mac}
                    setValue={setMac}
                    prefix={<SearchOutlined />}
                    placeholder="Search by MAC Address"
                />
            </div>
            {
                data.length > 0 &&
                <Table
                    rowKey="id"
                    dataSource={data}
                    columns={columns}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                setOpen(true);
                                setCurrent(record);
                            },
                        }
                    }}
                    className={styles['table-row']}
                    scroll={{ x: 'max-content' }}
                    loading={loading}
                    bordered
                    pagination={false}
                />
            }
            <InfoDrawer
                device={current}
                open={open}
                onClose={() => {
                    setOpen(false);
                    setCurrent(null);
                }}
            />
        </PageTemplate>
    )
}
