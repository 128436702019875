import React, { useState, useEffect } from 'react';

import { Tag, Table } from 'antd';

import dateFormat from '../../../utils/dateFormat';

import { REQUESTS } from '../../../api/requests';

import styles from "../index.module.scss"

export default function ActivationLogs({ device }) {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text, record, index) => index + 1,
        },
        {
            title: "By",
            align: "center",
            dataIndex: "by",
        },
        {
            title: "Type",
            align: "center",
            dataIndex: "type",
            render: (text) => <Tag color={text === "activated" ? "green" : 'volcano'} key={text}>
                {text}
            </Tag>
        },
        {
            title: "Date",
            align: "center",
            dataIndex: "createdAt",
            render: (text) => dateFormat(text),
        },

    ];

    const getActivationLogs = () => {
        if (!device) return;

        setLoading(true);

        const query = {
            app_id: device.app_id,
            device_id: device.id
        }

        REQUESTS.DEVICE_ACTIVATIONS(query, (data) => {
            setLoading(false)
            setData(data)
        }, (err) => setLoading(false))
    }

    useEffect(() => {
        getActivationLogs()
    }, [device]);

    return (
        <>
            <h3 className={styles["table-title"]}>Activation Logs</h3>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                loading={loading}
                scroll={{ x: "max-content" }}
                size="small"
            />
        </>
    )
}
