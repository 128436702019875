import React, { useState, useEffect } from 'react'

import { Button, Flex, Table, Tooltip } from 'antd';

import { CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import ActionDropdown from '../../../components/ActionDropdown';
import dateFormat from '../../../utils/dateFormat';
import { REQUESTS } from '../../../api/requests';
import PlaylistDrawer from './PlaylistDrawer';

import styles from "../index.module.scss"
import ConfirmModal from '../../../utils/ConfirmModal';

export default function DevicePlaylists({ device }) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [currentPlaylist, setCurrentPlaylist] = useState(null);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Url",
      dataIndex: "url",
      align: "center",
      render: (text) => {
        return <Tooltip title={text}>
          <div style={{ width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{text}</div>
        </Tooltip>
      }
    },
    {
      title: "Free DNS",
      dataIndex: "free_dns_id",
      align: "center",
      render: (text, record, index) => record && record.free_dns_id ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      align: "center",
      render: (text, record, index) => text ? dateFormat(text) : "N/A"
    },
    {
      title: "Protected",
      dataIndex: "pin",
      align: "center",
      render: (text, record, index) => record?.is_protected ? record.pin : <CloseOutlined style={{ color: "red" }} />
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record, index) => text || "-"
    },
    {
      title: "Expired date",
      dataIndex: "expired_date",
      align: "center",
      render: (text, record) => text ? dateFormat(text) : "N/A"
    },
    {
      title: "Selected",
      align: "center",
      render: (text, record) => record?.is_selected ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => (
        <ActionDropdown
          items={[
            {
              key: "edit",
              label: "Edit",
              icon: <EditOutlined />,
              onClick: () => {
                setShow(true);
                setCurrentPlaylist(record);
              }
            },
            {
              key: "delete",
              label: "Delete",
              icon: <DeleteOutlined />,
              onClick: () => ConfirmModal("Are you sure you want to delete?", () => {
                deletePlaylist(record.id);
              })
            },
          ]}
        />
      ),
    },
  ];

  const getPlaylists = () => {

    if (!device) return;

    setLoading(true);

    const query = {
      app_id: device.app_id,
      device_id: device.id,
    };

    const callback = (res) => {
      setLoading(false);
      setData(res);
    };

    const errorCallback = (err) => {
      setLoading(false);
    };

    REQUESTS.PLAYLIST.GET(query, callback, errorCallback);
  }

  const deletePlaylist = (id) => {
    setLoading(true);

    const body = {
      app_id: device.app_id,
      device_id: device.id,
      playlist_id: id,
    };

    const callback = () => {
      setLoading(false);
      getPlaylists();
    };

    const errorCallback = (err) => {
      setLoading(false);
    };

    REQUESTS.PLAYLIST.DELETE(body, callback, errorCallback);
  }

  useEffect(() => {
    getPlaylists()
  }, [device]);

  return (
    <div className={styles['playlists']}>
      <Flex justify='space-between' align='center' className={styles['playlists-header']}>
        <h1 className={styles['playlists-title']}>Playlists</h1>
        <Button type='primary' onClick={() => {
          setShow(true);
        }}>Add Playlist</Button>
      </Flex>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={data}
        scroll={{ x: 'max-content' }}
      />
      <PlaylistDrawer
        open={show}
        onClose={() => {
          setShow(false);
          if (currentPlaylist) setCurrentPlaylist(null);
        }}
        getPlaylists={getPlaylists}
        device={device}
        current={currentPlaylist}
      />
    </div>
  )
}
