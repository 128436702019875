import PageTemplate from '../../components/PageTemplate'
import { Tabs } from 'antd'
import ResellersNotifications from './components/ResellersNotifications'
import AppsNotifications from './components/AppsNotifications'

export default function Notifications() {

    return (
        <PageTemplate title="Notifications">
            <Tabs
                defaultActiveKey="1"
                destroyInactiveTabPane={true}
                items={[
                    {
                        key: '1',
                        label: 'Users',
                        children: <AppsNotifications />,
                    },
                    {
                        key: '2',
                        label: 'Resellers',
                        children: <ResellersNotifications />,
                    },
                ]}
            />
        </PageTemplate>
    )
}
