import React, { useEffect, useState } from 'react';

import { useSearchParams } from "react-router-dom";

import { Table, DatePicker, Button, Space, Radio } from 'antd';

import { REQUESTS } from '../../../api/requests';

import dateFormat from '../../../utils/dateFormat';

import styles from "../index.module.scss";

export default function DevicePaymentHistory({ device }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            fixed: "left",
            align: "center",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            sorter: true,
            render: (text) => dateFormat(text),

        },
        {
            title: "Payment Method",
            dataIndex: "method",
            key: "method",
            align: "center",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            align: "center",
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
            align: "center",
        },

        {
            title: "Package Name",
            dataIndex: "package_name",
            key: "package_name",
            align: "center",
            render: (text, record, index) => text ? text : "N/A",
        },

        {
            title: "Subscription id",
            dataIndex: "subscription_id",
            key: "subscription_id",
            align: "center",
            render: (text, record, index) => text ? text : "N/A",
        },
        {
            title: "Device model",
            dataIndex: "device_model",
            key: "device_model",
            align: "center",
            render: (text, record, index) => text ? text : "N/A",
        },
    ];

    const getData = () => {
        if (!device) return;

        setLoading(true);

        const query = {
            app_id: device.app_id,
            device_id: device.id
        }

        REQUESTS.DEVICE_PAYMNETS(query, (data) => {
            setLoading(false);
            setData(data)
        }, () => setLoading(false))
    }

    useEffect(() => {
        getData()
    }, [device])

    return (
        <>
            <h3 className={styles['table-title']}>Payments</h3>
            <Table
                rowKey="id"
                loading={loading}
                columns={columns}
                dataSource={data}
                scroll={{ x: "max-content" }}
                size="small"
            />
        </>
    )
}
