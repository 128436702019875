import { Drawer } from 'antd';

import dateFormat from '../../../utils/dateFormat';

import DevicePlaylists from './DevicePlaylists';

import styles from '../index.module.scss';
import ActivationLogs from './ActivationLogs';
import DevicePaymentHistory from './DevicePaymentHistory';

export default function InfoDrawer({ device, open, onClose }) {

    if (!device) return null;

    return (
        <Drawer
            title="Device"
            placement="right"
            closable={true}
            onClose={onClose}
            open={open}
            width={"80%"}
        >
            <ul className={styles['device-info-list']}>
                <li>Mac: {device.mac}</li>
                <li>Model: {device.model} {device.os_version && `(${device.os_version})`}</li>
                <li>Payed: {device.payed ? "YES" : "NO"}</li>
                <li>Online: {device.online ? "YES" : "NO"}</li>
                <li>Last online: {device.last_online ? dateFormat(device.last_online) : "N/A"}</li>
                <li>Restore Key: {device.restore_key ? device.restore_key : "N/A"}</li>
                <li>
                    {device.payed ? "Expired date" : "Free trial expired"}:{device.payed ?
                        dateFormat(device.activation_expired) : device.free_trial == '1' ?
                            dateFormat(device.free_trial_expired) :
                            "Expired"}
                </li>
                <li>Ip: {device.ip ? device.ip : "N/A"}</li>
                <li>App version: {device.app_version ? device.app_version : "N/A"}</li>
                <li>Authorization Type: {device.auth_type == "otp" ? "Otp" : "Device key"}</li>
                <li>Otp: {device.code ? device.code : "N/A"}</li>
                <li>Device Key: {device.key ? device.key : "N/A"}</li>
            </ul>
            <DevicePlaylists device={device} />
            <ActivationLogs device={device} />
            <DevicePaymentHistory device={device} />
        </Drawer>
    )
}
