import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Button, Flex, Table, Input, Tooltip, notification } from 'antd';

import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../../api/requests';

import ActionDropdown from '../../../components/ActionDropdown';
import filterDropdown from '../../../components/FilterDropdown';

import dateFormat from '../../../utils/dateFormat';
import ConfirmModal from '../../../utils/ConfirmModal';

import ICONS from '../../../config/icons';

import useResize from '../../../hooks/use-resize';

import AppsNotificationDrawer from './AppsNotificationDrawer';

import StatisticsPopup from './StatisticsPopup';

import styles from "../index.module.scss";

export default function AppsNotifications() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState(["createdAt", "desc"]);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState({});
    const [search, setSearch] = useState('');

    const [date, setDate] = useState('');
    const [expDate, setExpDate] = useState('');
    const [sendDate, setSendDate] = useState('');
    const [actionType, setActionType] = useState('');
    const [current, setCurrent] = useState('');

    const [showStatistics, setShowStatistics] = useState(false);

    const windowSize = useResize();

    const apps = useSelector(state => state.apps.value);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            align: "center",
            render: (text, record, index) => (page - 1) * limit + index + 1
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            align: "center",
            width: 150,
            render: (text) => {
                return text.length > 50 ? <Tooltip title={text}>
                    {text.slice(0, 50) + "..."}
                </Tooltip> : text
            }
        },
        {
            title: 'Text',
            dataIndex: 'desc',
            key: 'desc',
            align: "center",
            width: 300,
            render: (text) => {
                return text.length > 50 ? <Tooltip title={<div dangerouslySetInnerHTML={{ __html: text }} />}>
                    <div dangerouslySetInnerHTML={{ __html: text.slice(0, 50) + "..." }} />
                </Tooltip> : <div dangerouslySetInnerHTML={{ __html: text }} />
            }
        },
        {
            title: 'Send To',
            dataIndex: 'apps',
            key: 'apps',
            align: "center",
            render: (text, record) => {
                let appsList = record.apps;
                return <div className={styles['apps-list']} >
                    {appsList && appsList.map((app, index) => {
                        let appData = apps.find(item => item.name === app);
                        return <Flex align='center' gap={8} key={index}>
                            {appData.name}
                        </Flex>
                    })}
                </div>
            }
        },
        {
            title: 'Filter',
            dataIndex: 'filter_type',
            key: 'filter_type',
            align: "center",
            filters: [
                {
                    text: 'All',
                    value: "all",
                },
                {
                    text: 'Platform',
                    value: "platform",
                },
                {
                    text: 'Status',
                    value: "status",
                },
                {
                    text: 'Mac',
                    value: "mac",
                },
            ],
            filterMultiple: false,
            render: (text, record) => {
                const filter_value = record.filter_value.replaceAll("_", " ");
                return record.filter_type == "all" ? record.filter_type : `${record.filter_type} / ${filter_value}`
            }
        },
        {
            title: 'Received',
            dataIndex: 'viewed',
            key: 'viewed',
            align: "center",
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: "center",
            filters: [
                {
                    text: 'Active',
                    value: "active",
                },
                {
                    text: 'Waiting',
                    value: "waiting",
                },
                {
                    text: 'Expired',
                    value: "expired",
                },
            ],
            filterMultiple: false,
        },
        {
            title: 'Start Date',
            dataIndex: 'sended_date',
            key: 'sended_date',
            align: "center",
            ...filterDropdown(setSendDate),
            sorter: true,
            render: (text, record) => text ? dateFormat(text) : "N/A"

        },
        {
            title: 'End Date',
            dataIndex: 'expired_date',
            key: 'expired_date',
            align: "center",
            ...filterDropdown(setExpDate),
            sorter: true,
            render: (text, record) => text ? dateFormat(text) : "N/A"
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: "center",
            ...filterDropdown(setDate),
            sorter: true,
            render: (text, record) => text ? dateFormat(text) : "N/A"
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <ActionDropdown
                    items={[
                        {
                            key: "repeat",
                            label: "Repeat",
                            icon: ICONS.REPEAT,
                            onClick: () => handleActions("repeat", record)
                        },
                        {
                            key: "edit",
                            label: "Edit",
                            icon: <EditOutlined />,
                            onClick: () => handleActions("edit", record),
                            disabled: record.status == "active"
                        },
                        {
                            key: "delete",
                            label: "Delete",
                            icon: <DeleteOutlined />,
                            onClick: () => handleActions("delete", record)
                        },
                        {
                            key: "freeze/unfreeze",
                            label: record.freeze ? "Unfreeze" : "Freeze",
                            icon: ICONS.FREEZE,
                            onClick: () => handleActions("freeze/unfreeze", record)
                        },
                        {
                            key: "statistics",
                            label: "Statistics",
                            icon: ICONS.STATISTICS,
                            onClick: () => handleActions("statistics", record)
                        }
                    ]}
                />
            }
        }
    ];

    function deleteFunc(id) {
        const callback = () => {
            getAppsNotifications();
            notification.success({ message: "Notification Deleted Successfully" });
        }

        REQUESTS.NOTIFICATIONS.DELETE({ id }, callback,);
    }

    function freezeFunc(id) {
        const callback = () => {
            getAppsNotifications();
        }
        REQUESTS.NOTIFICATIONS.FREEZE({ id }, callback);
    }

    function handleActions(key, record) {
        switch (key) {
            case 'repeat':
                setOpen(true);
                setActionType('repeat');
                setCurrent(record);
                break;
            case 'edit':
                setOpen(true);
                setActionType('edit');
                setCurrent(record);
                break;
            case 'delete':
                ConfirmModal("Are you sure you want to delete?", () => deleteFunc(record.id));
                break;
            case 'freeze/unfreeze':
                ConfirmModal(`Are you sure you want to ${record.freeze ? "unfreeze" : "freeze"}?`, () => freezeFunc(record.id));
                break;
            case 'statistics':
                setShowStatistics(true);
                setCurrent(record);
                break;
            default:
                break;
        }
    };

    const getAppsNotifications = () => {
        setLoading(true);

        const params = {
            limit,
            page,
            sort,
            filter: {
                type: "device"
            }
        }

        if (filter.status && filter.status[0]) {
            params["filter"] = {
                status: filter.status[0]
            }
        }

        if (filter.filter_type && filter.filter_type[0]) {
            params["filter"] = {
                filter_type: filter.filter_type[0]
            }
        }

        if (search.length) {
            params.search = {
                title: search,
                desc: search,
                filter_value: search
            }
        }
        const callback = (data) => {
            setData(data.rows);
            setTotal(data.count);
            setLoading(false);
        };

        const errorCallback = (error) => {
            setLoading(false);
        };

        const dateFilter = [
            { between: 'createdAt', date: date },
            { between: 'expired_date', date: expDate },
            { between: 'sended_date', date: sendDate }
        ]

        dateFilter.forEach((item) => {
            if (item.date.length && item.date[0] && item.date[1]) {
                params['between'] = {
                    [item.between]: {
                        from: item.date[0] + " 00:00",
                        to: item.date[1] + " 23:59"
                    }
                }
            }
        })

        REQUESTS.NOTIFICATIONS.GET({ query: JSON.stringify(params) }, callback, errorCallback)
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setLimit(pagination.pageSize);
        if (sorter.field) setSort([sorter.field, sorter.order === "ascend" ? "asc" : "desc"]);
        setFilter(filters);
    }

    useEffect(() => {
        let timoutID = setTimeout(() => {
            getAppsNotifications();
        }, 500);

        return () => clearTimeout(timoutID);
    }, [page, limit, sort, filter, date, expDate, sendDate, search]);

    return (
        <div>
            <Flex justify='space-between' align='center' gap={10} style={{ marginBottom: 20 }}>
                <Input
                    placeholder="Search"
                    prefix={<SearchOutlined />}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: 300 }}
                />
                <Button type='primary' onClick={() => { setOpen(true) }}><PlusOutlined />  Add Notification</Button>
            </Flex>
            <Table
                dataSource={data}
                columns={columns}
                scroll={{ x: "max-content" }}
                loading={loading}
                pagination={{
                    total,
                    showSizeChanger: true,
                    pageSize: limit,
                    current: page,
                }}
                onChange={handleTableChange}
                rowClassName={(record, index) => record.freeze ? styles['freeze'] : ''}
                size={windowSize < 900 ? "small" : "large"}
            />
            <AppsNotificationDrawer
                open={open}
                onClose={() => {
                    setOpen(false)
                    if (current) {
                        setCurrent('');
                        setActionType('');
                    }
                }}
                getData={getAppsNotifications}
                actionType={actionType}
                current={current}
            />
            <StatisticsPopup
                open={showStatistics}
                onClose={() => {
                    setShowStatistics(false);
                    setCurrent(null);
                }}
                current={current}
            />
        </div>
    )
}
