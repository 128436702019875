import { Modal } from 'antd';

const { confirm } = Modal;

export default function ConfirmModal(title, onOk) {
    confirm({
        title,
        onOk,
        okButtonProps: {
            type: 'primary',
            style: {
                backgroundColor: '#429229',
                borderColor: '#429229',
                hover: {
                },
            }
        },
        cancelButtonProps: {
            style: {
                borderColor: '#429229',
                color: '#429229',
                hover: {
                    color: '#fff',
                    backgroundColor: '#429229',
                }
            }
        },
    })
}
