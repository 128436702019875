import { useState, useEffect } from "react";

import { Drawer, Form, Input, Button, Checkbox } from "antd";

import Message from "../../../components/Message";

import { REQUESTS } from "../../../api/requests";

import styles from "../index.module.scss";

export default function PlaylistDrawer({ open, onClose, device, current, getPlaylists }) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [isProtected, setIsProtected] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const onValuesChange = (changedValues, allValues) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  const onFinish = (values) => {
    const { name, url, pin, confirm_pin, hide_url } = values

    setLoading(true);

    let body = {
      name,
      url,
      app_id: device.app_id,
      // deviceId: device.id,
      hide_url
    };

    if (current) {
      body["id"] = current.id;

      body["is_protected"] = isProtected;

      if (isProtected) {
        body["pin"] = values.pin;
        body["confirm_pin"] = values.confirm_pin;
      }

    } else {
      body['deviceId'] = device.id;
      if (isProtected) {
        body["pin"] = pin;
        body["confirm_pin"] = confirm_pin;
        body["is_protected"] = isProtected;
      }
    }

    function callback() {
      setLoading(false);
      onClose();
      getPlaylists();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    if (current) {
      REQUESTS.PLAYLIST.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.PLAYLIST.ADD(body, callback, errorCallback);
    }
  };

  useEffect(() => {
    if (current && open) {
      form.setFields([
        {
          name: "name",
          value: current.name,
        },
        {
          name: "url",
          value: current.url,
        },
        {
          name: "pin",
          value: current.pin,
        },
        {
          name: "confirm_pin",
          value: current.pin,
        },
        {
          name: "hide_url",
          value: current.hide_url,
        },
      ]);

      setIsProtected(current.is_protected);
    }
  }, [current, open]);

  useEffect(() => {
    if (!open) {
      setLoading(false);
      setIsProtected(false);
      form.resetFields();
      setMessage({ type: false, text: "" });
    }
  }, [open]);

  return (
    <Drawer
      title={current ? "Edit Playlist" : "Add Playlist"}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="M3U8 URL"
          name="url"
          rules={[
            {
              required: true,
              message: "Please input M3U8 url!",
            },
            {
              type: 'url',
              warningOnly: true,
              message: 'The input is not valid URL!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Checkbox
          checked={isProtected}
          onChange={(e) => setIsProtected(e.target.checked)}
          className={styles['protect-checkbox']}
        >
          Protect Playlist
        </Checkbox>

        <Form.Item
          name="pin"
          label="Set PIN"
          rules={[
            {
              required: current && current.is_protected ? false : isProtected,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            disabled={!isProtected}
          />
        </Form.Item>

        <Form.Item
          name="confirm_pin"
          dependencies={["pin"]}
          label="Confirm PIN"
          rules={[
            {
              required: current && current.is_protected ? false : isProtected,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pin") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            disabled={!isProtected}
          />
        </Form.Item>

        <Form.Item name="hide_url" valuePropName="checked">
          <Checkbox>Hide URL</Checkbox>
        </Form.Item>

        <Message message={message} />

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
            className={styles['submit-btn']}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
